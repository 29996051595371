html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.navbar-home {
  background-color: black;
  margin: 0;
  padding: 0 ;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  display: flex;
  position: fixed;
  font-family: "Timmana", sans-serif;

  z-index: 1000;

}



.logo {
  height: 60px; /* Set a fixed height for the logo */
  margin-left: 10px; /* Add spacing between the logo and text */
}

/* Container for the background and button */
.background-container {
  position: relative; /* Make it the parent for absolute positioning */
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio based on the image */
  display: flex;
  justify-content: center; /* Center contents horizontally */
  align-items: center; /* Center contents vertically */
margin-top: 50px;
}

/* Background image */
.background-image {
  width: 100%; /* Scale to fit the container width */
  height: auto; /* Maintain aspect ratio */
}

/* Download button */
.download-button {
  position: absolute;
  display: flex; /* Use Flexbox for alignment */
  align-items: center; /* Align icon and text vertically */
  justify-content: center; /* Center content horizontally */
  background-color: black;
  bottom: 5%; /* Adjust the vertical position */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset the button by its width */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  /* font-weight: bold; */
  cursor: pointer;
  font-family:"Poppins", sans-serif ;
  z-index: 2;
  font-size: 0.9rem; /* Consistent text size */
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #333;
}

.button-icon {
  height: 20px; /* Set a consistent height for the icon */
  margin-right: 10px; /* Add spacing between icon and text */
}

.button-text {
  display: inline-block;
}

.navbar-title-home {
  color: white;
  padding: 10px;
  margin: 0;
}

/* Header content with download button */
.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top:0 ;
  /* height: 30vh; */
  /* position: relative; */

}


.main-screen{
  display: flex;
  flex-direction: column;
}

.about-container {
  max-width: 800px;
  padding: 20px;
  align-self: center;
  text-align: center; /* Center-align all content */
}

/* .about-container h2, .about-container h4 {
  color: #333;
  margin-bottom: 10px;
} */

.about-container p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}


/* Spacer to ensure footer visibility */
.spacer {
  height: 10vh;
  width: 100%;
  background-color: white;
}

/* Fixed white box that contains the button */
.fixed-download-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
}

.fixed-download-link {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.fixed-download-button {
  background-color: #4CAF50;
  color: white;
  width: 90%;
  height: 70%;
  font-size: 2.5vh;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  font-family:"Poppins", sans-serif ;

}

/* Button hover effect */
.fixed-download-button:hover {
  background-color: #45a049;
}/* Rewards Section */
.rewards-section {
  text-align: center;
margin-left: 20px;
margin-right: 20px;

}

.rewards-heading {
  /* font-size: 2rem; */
  font-family: 'Cinzel', serif;
  color: #333;
  margin-bottom: 20px;
}

.rewards-steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.step {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 600px;
  background-color: #f9f9f9;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.step-icon {
  font-size: 2.5rem;
  color: #4CAF50;
}

.step-content h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
  margin: 0;
}

.step-content p {
  font-size: 0.9rem;
  color: #666;
  margin: 5px 0 0;
}

/* Gift Cards Showcase */
.gift-cards-heading {
  font-size: 1.8rem;
  font-family: 'Cinzel', serif;
  margin-top: 40px;
  color: #333;
}

.gift-cards-description {
  font-size: 1rem;
  color: #666;
  margin: 10px 0 20px;
}





/* Responsive adjustments for mobile devices */
@media (max-width: 600px) {
  .fixed-download-button {
    width: 95%;
    height: 60%; 
    font-size: 2vh; 
  }

  .download-button {
    width: auto;
    padding: 8px 16px;
  }
}

/* Section containing the gift cards */
.gift-card-section {
  /* position: relative; */
  /* width: auto; Full width of the viewport */
  overflow: hidden; /* Hide overflowing content */
  background-color: #f9f9f9; /* Optional background for visual appeal */
  padding: 20px 0;
}

/* Row of gift cards */
.gift-card-row {
  /* display: ; Arrange cards in a row */
  display: inline-flex;
  padding: 5;
  margin-bottom: 100;
  /* animation: scroll 15s linear infinite; Continuous scrolling animation */
}

/* Individual gift card styling */
.gift-card {
  flex-shrink: 0; /* Prevent cards from shrinking */
  width: 200px; /* Fixed width for each card */
  height: 120px; /* Fixed height for each card */
  margin-right: 10px; /* Spacing between cards */
  object-fit: cover; /* Ensure image fits nicely inside card */
  border-radius: 8px; /* Rounded corners for cards */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for visual depth */
  transition: transform 0.2s; /* Smooth zoom effect on hover */
}

.gift-card:hover {
  transform: scale(1.05); /* Slight zoom-in effect when hovered */
}

.gift-card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.move-right {
  animation: scrollRight 25s linear infinite;
}
.move-left {
  animation: scrollLeft 25s linear infinite;
}

@keyframes scrollRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
