/* General Styles */
.download-screen {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: black;
  /* text-align: center; */
  height: 100vh ;

}

.navbar-download {
  background-color: black;
  width: 100%;
  font-size: 30px;
  color:white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Timmana", sans-serif;
  height: auto;
}
.navbar-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.safe-secure{
  margin-top: 10px;
  display: flex;

justify-content: center;
  font-weight: 700;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.downloading-state {
  margin: 20px 0;
  align-self: center;
text-align: center;
}

.how-to {
  font-size: 18px;
  margin: 20px 0;
  text-align: center;
}

.highlight {
  color: red;
}

.instructions-container {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  align-items:baseline;
  flex-wrap: nowrap;
  gap: 5px;
  margin-top: 20px;
}

.step-number{
  font-weight: bold;
  margin-right: 5px;
}
.download-step{
  flex:0 0 82% ;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
/* Instruction Box */
.instruction {
  /* text-align: left; */
}

.instruction-text{
  display: flex;
  align-items: baseline;
  flex-direction: row;
  margin-left: 5px;
}
.mock-action {
  background: whitesmoke;
  border-radius: 10px;
  border: black solid 1px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.app-info{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;


}
/* Step 1: Pop Animation */
.pop-animation {
  animation: pop 1s infinite ease-in-out;
}

@keyframes pop {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.file-warning  {
  color: #444;
  font-size: 14px;
  display: flex;
  flex-direction: column;
/* text-align: start; */
}

.unknown-sources{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight:600
}
.unknown-radio{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  /* align-items: baseline; */
}

.warning-buttons{
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  /* align-items: flexflex-end; */
}

.buttons{
  display: flex;
  flex-direction: row;
  align-self: center;
}

/* Step 2: File Open */
.file-open {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 10px;
}

.file-name {
  color: #333;
}


.button{
  margin: 0px 5px;
  height: 30px;
  border-radius: 5px;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.positive-button{
  background-color:  #A5D6A7;
}

.mock-action-logo{
  height: 30px;
  width: 30px;
}
.mock-action-app-logo{
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

.mock-action-question{
  align-self: center;
}

/* Step 3: Toggle Animation */
.radio {
  width: 30px;
  height: 15px;
  background: gray;
  border-radius: 7px;
  position: relative;
  align-self: center;
  margin-left: 5px;
}
.app-name{
  font-weight: 700;
  margin-left: 5px;
}

.toggle {
  width: 14px;
  height: 14px;
  background: white;
  border: 1px solid #999;
  border-radius: 50%;
  position: absolute;

  animation: toggle 2s infinite alternate;
}

@keyframes toggle {
  0% {
    left: 1px;
    color:grey;
  }
  100% {
    left: 14px;
  }
}

/* Step 4: Zoom Animation */
.zoom-animation {
  animation: zoom 1s infinite;
}

@keyframes zoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}