.faq-container {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 0; /* Removed circular borders */
}

.faq-heading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* FAQ item styling */
.faq-item {
  margin-bottom: 15px;
  background-color: white;
  border-radius: 0; /* Removed circular borders */
  border: 1px solid #ddd;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* FAQ question styling */
.faq-question {
  padding: 15px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 0; /* Removed circular borders */
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #e0e0e0;
}

/* FAQ answer styling */
.faq-answer {
  padding: 15px;
  font-size: 16px;
  color: #666;
  border-top: 1px solid #ddd;
  background-color: white;
  animation: fadeIn 0.3s ease-in-out;
}

/* Simple fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Icon styling for the FAQ question */
.faq-icon {
  font-size: 16px;
  transition: transform 0.3s ease;
}

/* Rotate the icon when the FAQ is open */
.faq-icon.open {
  transform: rotate(180deg);
}